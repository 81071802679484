export const GENDERS = [
  { full: "g_male", short: "M" },
  { full: "g_female", short: "F" },
];
export const T_SHIRT_SIZES = [
  "Women's Small",
  "Women's Medium",
  "Women's Large",
  "Women's X-Large",
  "Women's 2X-Large",
  "Men's Small",
  "Men's Medium",
  "Men's Large",
  "Men's X-Large",
  "Men's 2X-Large",
];

export const TS_CHECKOUT_COUNTRIES = [
  "us",
  "mx",
  "eu",
  "ch",
  "mt",
  "hr",
  "gb",
  "ca",
  "cc",
  "th",
  "si",
  "in",
  "ae",
  "cr",
];
